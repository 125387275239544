.rr-player {
  position: relative;
  box-shadow: none;
  width: 650px;
  /* Set the width */
  height: 675px;
  /* Set the height */
  padding-top: 35px;
}

.rr-progress__handler {
  background: #2f9ee9 !important;
}

.rr-controller__btns button.active {
  background: #2f9ee9 !important;
}

.switch input[type='checkbox']:checked+label:before {
  background: #2f9ee9 !important;
}

.rr-player__frame {
  position: relative;
  width: 100%;
  /* Ensure the frame spans the entire width */
  margin-top: 50px;
  /* margin-bottom: 100px; */
}

.rr-controller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  /* Ensure the rr-controller appears above rr-player__frame */
}

.rr-timeline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  padding-top: 2px;
}

.replayer-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
}