@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.font-bold {
    font-weight: 600;
}

.header-container {
    display: flex;
    align-items: self-start;

    .align-items-center {
        align-items: center !important;
        padding-top: 2px;
    }
}