.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Make sure it takes full height */
  width: 100%; /* Make sure it takes full width */
}

.error-content {
  text-align: center;
}

/* Additional style to ensure the ErrorPage takes full height of the parent */
.prospects-consent-container {
  height: 100%;
}