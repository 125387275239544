
#cc-status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.cc-spinner-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: cc-spinner-chase 2.5s infinite linear both;
}

.cc-spinner-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: cc-spinner-chase-dot 2.0s infinite ease-in-out both; 
}

.cc-spinner-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #2f9ee9;
  border-radius: 100%;
  animation: cc-spinner-chase-dot-before 2.0s infinite ease-in-out both; 
}

.cc-spinner-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.cc-spinner-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.cc-spinner-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.cc-spinner-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.cc-spinner-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.cc-spinner-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.cc-spinner-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.cc-spinner-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.cc-spinner-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.cc-spinner-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.cc-spinner-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.cc-spinner-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes cc-spinner-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes cc-spinner-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes cc-spinner-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}