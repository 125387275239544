.custom-pointer {
    cursor: pointer !important;
}

.fs-10 {
    font-size: 10px;
    color: #000;
}

.fs-13 {
    color: #000;
    font-size: 13px;
}

.fs-12 {
    color: #000;
    font-size: 12px;
}

.fs-14 {
    color: #000;
    font-size: 14px;
}

.fs-15 {
    color: #000;
    font-size: 15px;
}

.fs-16 {
    color: #000;
    font-size: 16px;
}

.fs-17 {
    color: #000;
    font-size: 17px;
}

.fs-18 {
    color: #000;
    font-size: 18px;
}

.fs-20 {
    color: #000;
    font-size: 20px;
}

.fs-22 {
    color: #000;
    font-size: 22px;
}

.fs-24 {
    color: #000;
    font-size: 24px;
}

.fs-32 {
    color: #000;
    font-size: 32px;
}

.fs-45 {
    color: #000;
    font-size: 45px;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.fsnormal {
    font-style: normal;
    font-size: normal !important;
    font-weight: normal !important;
}

.link-primary {
    color: #2f9ee9 !important;
}


.custom-table-header {
    font-size: 13px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    color: #414141;
    width: 100% !important;
    z-index: 2;
}

.custom-table-header .th {
    position: absolute;
    top: 0;
}

.cc-filters-position-relative {
    max-height: 100vh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.cc-filters-position-relative::-webkit-scrollbar {
    display: none;
}

.custom-green,
.custom-green::after,
.custom-green::before {
    color: #36b76f !important;
}

.custom-scrollbar::-webkit-scrollbar {
    display: none;
    -o-animation-delay: calc(1s * 0);
    -ms-animation-delay: calc(1s * 0);
    -moz-animation-delay: calc(1s * 0);
    -webkit-animation-delay: calc(1s * 0);
    animation-delay: calc(1s * 0);
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.custom-scrollbar::-webkit-scrollbar {
    -o-animation-delay: calc(1s * 0);
    -ms-animation-delay: calc(1s * 0);
    -moz-animation-delay: calc(1s * 0);
    -webkit-animation-delay: calc(1s * 0);
    animation-delay: calc(1s * 0);
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    display: block;
    width: 8px;
    background-color: #F5F5F5;
}


.custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.186);
    background-color: #c7c7c7;
}


.custom-multi-select-checkbox input[type=checkbox] {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.custom-multi-select-checkbox input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #2f9ee9;
    border: 1px solid #2f9ee9;
    border-radius: 3px;
}


.custom-multi-select-checkbox input[type=checkbox]:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.custom-multi-select-checkbox input[type=checkbox]:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    ;
    border-width: 0 0px 0px 0;
    position: absolute;
    top: 1px;
    left: 4px;
}

.custom-multi-select-checkbox input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
    position: absolute;
}

.css-1r4vtzz {
    width: 100% !important;
    height: 38px !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-size: 13px !important;
    position: relative !important;
}

.css-1r4vtzz .css-1v99tuv {
    max-width: 75% !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.css-48ayfv .css-1v99tuv {
    /* Text to stay ellipsed after click*/
    max-width: 75% !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.css-1r4vtzz .css-1gpjby2 {
    /* Dropdown icon position before click*/
    position: relative !important;
    margin-left: auto;
    right: 0 !important;
    padding-left: 3px !important;
    display: flex;
    align-items: center;
    border-left: 1px solid #ced4da !important;
}

.css-48ayfv {
    width: 100% !important;
    height: 38px !important;
    border: 1px solid #ced4da !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-size: 13px !important;
    position: relative !important;
}

.css-48ayfv .css-1gpjby2 {
    /* Dropdown icon position after click*/
    position: relative !important;
    margin-left: auto;
    right: 0 !important;
    padding-left: 3px !important;
    display: flex;
    align-items: center;
    border-left: 1px solid #ced4da !important;
    height: 15 !important;
    width: 20 !important;
}

.css-1qprcsu-option {
    background-color: #fff !important;
    font-weight: normal !important;
}

/* checkbox styling for drodown */
.css-1qprcsu-option input[type=checkbox] {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.css-1qprcsu-option input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #2f9ee9;
    border: 1px solid #2f9ee9;
    border-radius: 3px;
}


.css-1qprcsu-option input[type=checkbox]:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 3px;
}


.css-1qprcsu-option input[type=checkbox]:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    ;
    border-width: 0 0px 0px 0;
    position: absolute;
    top: 1px;
    left: 4px;
}

.css-1qprcsu-option input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
    position: absolute;
}

/* checkbox styling for drodown ends */

.css-1pcexqc-container {
    border: 1px solid lightgray !important;
    border-radius: 4px !important;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 4%) !important;
    /* padding-left: 12px!important; */
}

.apexcharts-canvas .apexcharts-svg {
    min-height: 62px !important;
    /* To compensate for the cutting of the stroke in mini-widgets dashboard */
}

.css-11unzgr {
    border-bottom: #fff !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.css-11unzgr::-webkit-scrollbar {
    display: none;
}

.sf-checkbox input[type=checkbox] {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
}

.sf-checkbox input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #2f9ee9;
    border: 1px solid #2f9ee9;
    border-radius: 3px;
}


.sf-checkbox input[type=checkbox]:before {
    content: "";
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 3px;
}


.sf-checkbox input[type=checkbox]:after {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-width: 0 0px 0px 0;
    position: absolute;
    top: 1px;
    left: 4px;
}

.sf-checkbox input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
    position: absolute;
}

.compared--data {
    position: absolute;
    top: 18px;
    right: 0;
}

.sfred {
    color: #f46a6a;
}

.switch.svelte-9brlez label.svelte-9brlez.svelte-9brlez {
    width: 2em;
    height: 1em;
    position: relative;
    cursor: pointer;
    display: block;
    margin-top: 8px;
}

.sfgreen {
    color: #8dd397;
}

.sfred {
    color: #f46a6a;
}

.sfyellow {
    color: #f19927;
}

.sfmuted {
    color: #c1c1c1;
}

.table-overflow-auto {
    overflow: auto;
}

.selected_filter {
    background-color: #f6f5f5;
    border-radius: 5px;
    padding: 0px 15px;
}

.box-w {
    min-width: 220px;
}

.red-green-bg-switch-btn input {
    color: #fff;
    border: 1px solid #f46a6a;
    background-color: #f46a6a;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}
  
.red-green-bg-switch-btn input:checked {
    border: 1px solid #8dd397;
    background-color: #8dd397;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.retail-dropdown-width{
    width:70%;
}

@media (max-width: 1440px) {
    .flex-direction-graph-list{
        flex-direction: column;
    } 
    .dashboard-align-items-start{
        align-items: flex-start;
    }

    .dashboard-values-margin{
        margin: 0 17px;
    }
}

.bg-token-header{
    background-color: #f2f2f5;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 8px;
}

.bg-token-header{
    background-color: #f2f2f5;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 8px;
}

.table-screen-height{
    height: calc(100vh - 330px);
    overflow: auto;
}