/*******************************
* MODAL AS ADMIN USER SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

    @media (min-width:1000px) and (max-width: 1279px) {
            
        .modal .admin-filter-users.modal-dialog {
            right: -8%; 
        }
        
    }

    @media (min-width:1280px) and (max-width: 1365px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 0; 
        }
        
    }

    @media (min-width:1366px) and (max-width: 1439px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 3%; 
        }
        
    }

    @media (min-width:1440px) and (max-width: 1535px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 5%; 
        }
        
    }

    @media (min-width:1536px) and (max-width: 1599px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 8%; 
        }
        
    }

    @media (min-width:1600px) and (max-width: 1832px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 10%; 
        }
    }

    @media (min-width:1833px) and (max-width: 1919px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 14.5%; 
        }
    }


    @media (min-width:1920px) and (max-width: 2255px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 16%; 
        }
    }

    @media (min-width:2256px) and (max-width: 2559px){
        
        .modal .admin-filter-users.modal-dialog {
            right: 21%; 
        }
    }

    @media (min-width:2560px) and (max-width: 2735px){
        
        .modal .admin-filter-users.modal-dialog {
            right: 25%; 
        }
    }

    @media (min-width:2736px) and (max-width: 3199px){
        
        .modal .admin-filter-users.modal-dialog {
            right: 26%; 
        }
    }

    @media (min-width:3200px) and (max-width: 3839px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 30%; 
        }
    }

    @media (min-width:3840px) {
        
        .modal .admin-filter-users.modal-dialog {
            right: 33%; 
        }
    }

    .modal .admin-filter-users.modal-dialog {
        position: relative;
        margin-top: 169px;
        width: 450px;
        height: fit-content;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal .admin-filter-users .modal-content {
        /* overflow-y: scroll; */
        -ms-overflow-style: none !important;  /* IE and Edge */
        scrollbar-width: none !important;  /* Firefox */
    }

    .modal .admin-filter-users.modal-body {
        padding: 15px 15px 80px;
    }

    /*Right*/
    .modal .admin-filter-users.modal-dialog {
        -webkit-transition: opacity 0.4s linear, right 0.4s ease-out;
        -moz-transition: opacity 0.4s linear, right 0.4s ease-out;
        -o-transition: opacity 0.4s linear, right 0.4s ease-out;
        transition: opacity 0.4s linear, right 0.4s ease-out;
    }


    .modal .admin-filter-users.modal-dialog .modal-content {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
        border-radius: 10px;
        border: none;
    }

    .modal-body {
        /* 100% = dialog height, 120px = header + footer */
        max-height: calc(100% - 120px);
        overflow-y: scroll;
    }


.border-bottom {
    border-bottom: 1px solid #eff2f7;
}

.numberCircle {
    height: 16px;
    width: 16px;
    border: 1px solid #fff;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    padding: 1px;
    background: #fff;
    color: #2f9ee9;
    text-align: center;
    font-size: 10px;
    margin-left: 5px;
}

.filter-btn {
    color: #fff;
    background-color: #2f9ee9;
    border-color: #2f9ee9;
}

.filter-btn-w {
    width: 108.85px;
}

.filter-text {
    font-weight: 400;
}

.align_top tr {
    vertical-align: top !important;
}

.selected_filter {
    background-color: #f6f5f5;
    border-radius: 5px;
    padding: 0px 15px;
}
