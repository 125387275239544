.card-body-wrapper {
  height: 70vh;
  overflow: hidden;
  position: relative;
}

.cosent-card {
  min-height: "650px" !important;
  max-height: "650px" !important;
  overflow: "auto",
}

.float-deatils {
  width: 27%;
  float: left;
}

.float-consent {
  width: 72%;
  float: left;
  padding-left: 15px;
}